import { TYPE } from "vue-toastification";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";

export interface StepConnection {
  stepId: string,
  stepName: string,
  specId: string,
  requiresCreate: boolean,
  connectionId: string,
  connection: any,
  addNew: boolean,
  nameExists: boolean
  uniqueConnection: boolean
  selectedName: string,
}

export async function getConnectionList(): Promise<any> {
  const resource = "/connections";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnection(connectionId: string): Promise<any> {
  const endpoint = `/connections/find/${connectionId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function saveConnection(connection: any): Promise<any> {
  const endpoint = "/connections/add";
  const response = await apiCall("POST", endpoint, connection);
  if (response.code === 200) {
    if (response.result?.code === "1011") {
      showAlert(TYPE.WARNING, "Connection with the same name exists.");
      return undefined;
    }
    return response.result[0];
  }
  if (response.code === 406) {
    showAlert(TYPE.WARNING, response.message);
    return undefined;
  }
  logResponse(response);
  return undefined;
}

export async function editConnection(connection: any): Promise<any> {
  const endpoint = "/connections";
  const response = await apiCall("PUT", endpoint, connection);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function deleteConnection(connectionId: string): Promise<any> {
  const resource = `/connections/${connectionId}`;
  const response = await apiCall("DELETE", resource);
  if (response.code === 200 || response.code === 409) {
    return response.code;
  }
  logResponse(response);
  return 500;
}

export async function getConnectorList(): Promise<any> {
  const resource = "/connectors";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getOrganizationsConnectorList(): Promise<any> {
  const resource = "/connectors/organization";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnectorSpecs(): Promise<any> {
  const resource = "/specs";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnectorSpecById(specId: string): Promise<any> {
  const resource = `/specs/${specId}`;
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return {
      spec: response.result,
      connectionSetting: response.result.connectionSettingsSchemas,
    };
  }
  logResponse(response);
  return undefined;
}

export async function getConnectionListing(params: any): Promise<any> {
  const endpoint = "/connections/listing";
  const response = await apiCallParams("GET", endpoint, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return undefined;
}

export async function inLineEditConnection(connectionId: string, connection: any): Promise<any> {
  const endpoint = `/connections/edit/${connectionId}`;
  const response = await apiCall("POST", endpoint, connection);
  if (response.code === 200) {
    if (response.result?.code === "1011") {
      showAlert(TYPE.WARNING, "Connection with the same name exists.");
      return undefined;
    }
    return true;
  }
  logResponse(response);
  return undefined;
}

export function convertToDefinitions(data) {
  if (data) {
    const definitions = {};
    Object.entries(data)
      .forEach(([key, val]: any[]) => {
        const value = val === "*****" ? null : val;
        definitions[key] = {
          name: key,
          value,
        };
      });
    return definitions;
  }
  return undefined;
}

export function convertObject(data) {
  const object = {};
  if (data) {
    Object.entries(data)
      .forEach(([key, value]: any[]) => {
        object[key] = value.value;
      });
  }
  return object;
}

export async function getOAuthUrl(connectionId: string): Promise<any> {
  const endpoint = "/oauth2/generate-authorization-url";
  const response = await apiCallParams("GET", endpoint, {
    connectionId,
  });
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function verifyOauth(connectionId: string): Promise<any> {
  const endpoint = "/oauth2/verifyConnectivity";
  const response = await apiCallParams("GET", endpoint, {
    connectionId,
  });
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function disconnectOAuth(connectionId: string):Promise<any> {
  const endpoint = "/oauth2/disconnectOAuthConnectivity";
  const response = await apiCallParams("GET", endpoint, {
    connectionId,
  });
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConnectorAuthSchemas(connectorSpecId: string): Promise<any> {
  const endpoint = "/connectors/spec";
  const response = await apiCallParams("GET", endpoint, {
    connectorSpecId,
  });
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
